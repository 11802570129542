<template>
    <div class="supervisory-container">
        <div class="mask" v-if="loading && !info.error" @click="clickHandle">
            <div class="spinner-border"></div>
        </div>
        <div class="mask2" style="flex-direction: column;" v-if="info.error">
            <img src="@/assets/error.png" alt="" style="width: 40px;height: 40px">
            <div style="color: #fff;">播放失败，请<span style="color: skyblue;" @click="refishHandle">刷新</span>重试！</div>
        </div>
        <div>
            <video width="100%" height="100%" :class="info.uuid" preload="auto" muted autoplay type="rtmp/flv"
                ref="myVideo" :poster="poster" v-if="showVideo" style="position: relative;z-index: 1;">
                <source src="">
            </video>
        </div>
        <!--         <div class="controls" v-show="controls && show && info.url.indexOf('tracks') !== -1">
            <Sider :progress="timer" @changeHandle="changeHandle" ref="siderRef" @touchendHandle="touchendHandle" />
        </div> -->
    </div>
</template>

<script>
import flvjs from '@/utils/flv.min'
import axios from 'axios'
import Sider from '@/components/sider.vue'
export default {
    components: { Sider },
    props: {
        info: {
            type: Object,
            default: () => { }
        },
        controls: {
            type: Boolean,
            default: false
        },
        timer: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            flvPlayer: null,
            show: false,
            playTime: false,
            time: null,
            countDown: null,
            showVideo: true,
            error: false,
            maskHeight: 220,
            progress: {
                min: 0,
                max: 0
            },
            loading: true,
            playStatus: false,
            poster: require('@/assets/man.jpg')
        }
    },
    mounted() {
        this.$emit('update:callback', this.play)
        this.registerObserver()
        this.onVideoLoaded()
    },
    methods: {
        onVideoLoaded() {
            const img = this.$refs.myVideo;
            this.maskHeight = img.clientHeight;
        },
        registerObserver() {
            if (!this.observer) {
                this.observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            console.log('进入了可视区域', this.info.name)
                            if (this.playStatus) return
                            this.playTime = setTimeout(() => {
                                this.playStatus = true
                                this.play()
                            }, 1500)
                        } else {
                            if (this.playTime) clearTimeout(this.playTime)
                            if (this.info?.status != 2) return
                            this.playStatus = false
                            this.destroyHnalde()
                            console.log('离开了可视区域', this.info.name)
                        }
                    });
                }, {
                    threshold: 1
                });
            }
            this.observer.observe(this.$refs.myVideo);
        },
        async play() {
            this.playStatus = true
            // https://hengyidasha1frp.lylxwy.com/websocket/connect
            if (!this.showVideo) this.showVideo = true
            const res = await axios(`${this.info.custom_url}/websocket/connect`, {
                method: 'post',
                data: JSON.stringify(this.info),
                headers: {
                    "Content-Type": "application/json",
                },
            })
            this.poster = this.info.category_name.includes('侨声尚品郡') ? `${this.info.custom_url}/${this.info.category_id}-${this.info.device_url.replace('tracks', 'Channels').replace(/(Channels\/\d+?)(\d)(\?|$)/, (match, p1, p2, p3) => {
                return p1 + '2' + p3
            }).match(/Channels\/(\d+)/)[1]}.jpg` : `${this.info.custom_url}/${this.info.category_id}-${this.info.device_url.replace('tracks', 'Channels').replace(/(Channels\/\d+?)(\d)(\?|$)/, (match, p1, p2, p3) => {
                return p1 + '4' + p3
            }).match(/Channels\/(\d+)/)[1]}.jpg`
            console.log(this.poster, 'poster')
            this.$refs.myVideo.addEventListener('error', (err) => {
                this.$emit('update:error', true)
            });
            if (res.data.code === 200) {
                if (flvjs.isSupported()) {
                    try {
                        this.flvPlayer = flvjs.createPlayer({
                            type: 'flv',
                            url: `${this.info.custom_url.replace('https', 'wss')}/websocket/${this.info.uuid}`,
                            isLive: true,
                            hasAudio: false,
                            hasVideo: true,
                            enableStashBuffer: false
                        }, {
                            enableWorker: false,
                            enableStashBuffer: false,
                            autoCleanupSourceBuffer: true
                        });

                        this.flvPlayer.attachMediaElement(document.querySelector(`.${this.info.uuid}`));
                        setTimeout(() => {
                            this.flvPlayer.load();
                            this.flvPlayer.play();
                            // Event listeners
                            this.flvPlayer.on(flvjs.Events.ERROR, (errType, err, errDetail) => {
                                console.error('播放过程中因任何原因发生错误', errType, err, errDetail);
                            });
                            this.flvPlayer.on(flvjs.Events.LOADING_COMPLETE, () => {
                                console.log('输入的 MediaDataSource 已完全缓冲至结束');
                            });
                            this.flvPlayer.on(flvjs.Events.RECOVERED_EARLY_EOF, () => {
                                console.log('缓冲期间发生意外的网络 EOF，但已自动恢复');
                            });
                            this.flvPlayer.on(flvjs.Events.MEDIA_INFO, () => {
                                console.log('提供媒体的技术信息，如视频/音频编解码器、比特率等。');
                            });
                            this.flvPlayer.on(flvjs.Events.METADATA_ARRIVED, () => {
                                console.log('使用“onMetaData”标记提供 FLV 文件（流）可以包含的元数据。');
                            });
                            this.flvPlayer.on(flvjs.Events.SCRIPTDATA_ARRIVED, () => {
                                console.log('提供 FLV 文件（流）可包含的脚本数据（OnCuePoint / OnTextData）');
                            });
                            this.flvPlayer.on(flvjs.Events.TIMED_ID3_METADATA_ARRIVED, () => {
                                console.log('提供包含私有数据（stream_type=0x15）回调的定时 ID3 元数据数据包');
                            });
                            this.flvPlayer.on(flvjs.Events.SYNCHRONOUS_KLV_METADATA_ARRIVED, () => {
                                console.log('提供包含私有数据（stream_type=0x15）回调的同步 KLV 元数据数据包');
                            });
                            this.flvPlayer.on(flvjs.Events.ASYNCHRONOUS_KLV_METADATA_ARRIVED, () => {
                                console.log('提供包含私有数据（stream_type=0x06）回调的异步 KLV 元数据数据包');
                            });
                            this.flvPlayer.on(flvjs.Events.SMPTE2038_METADATA_ARRIVED, () => {
                                console.log('提供包含私有数据回调的 SMPTE2038 元数据包');
                            });
                            this.flvPlayer.on(flvjs.Events.SCTE35_METADATA_ARRIVED, () => {
                                console.log('提供包含部分 (stream_type=0x86) 回调的 SCTE35 元数据数据包');
                            });
                            this.flvPlayer.on(flvjs.Events.PES_PRIVATE_DATA_ARRIVED, () => {
                                console.log('提供包含私有数据（stream_type=0x06）回调的 ISO/IEC 13818-1 PES 数据包');
                            });
                            this.flvPlayer.on(flvjs.Events.STATISTICS_INFO, data => {
                                if (data.speed) {
                                    this.loading = false
                                    this.$emit('update:status', 2)
                                    this.$emit('update:error', false)
                                } else {
                                    this.loading = true
                                    this.$emit('update:status', 1)
                                }
                                /* console.log('提供播放统计信息，如丢帧、当前速度等。', this.info.name, data); */
                            });
                            this.flvPlayer.on(flvjs.Events.DESTROYING, () => {
                                console.log('当播放器开始拆卸时触发');
                            });
                        }, 2000)
                    } catch (error) {
                        console.error('An error occurred during player initialization:', error);
                    }
                } else {
                    alert("FLV.js is not supported");
                }
            }

        },
        async destroyHnalde() {
            this.flvPlayer.pause()
            this.flvPlayer.destroy()
            this.error = false
            const res = await axios(`${this.info.custom_url}/websocket/close`, {
                method: 'post',
                data: JSON.stringify(this.info),
                headers: {
                    "Content-Type": "application/json",
                },
            })
            return res.data
        },
        refishHandle() {
            this.flvPlayer.destroy && this.flvPlayer.destroy()
            this.showVideo = false
            this.showVideo = true
            this.$emit('update:error', false)
            this.loading = true
            setTimeout(() => {
                this.count = 1
                this.info.uuid = `test${Date.now()}`
                this.play()
            }, 2000)
        },
        clickHandle() {
            this.show = !this.show
            this.$emit('clickHandle', this.info)
        },
        changeHandle() {
            if (this.time) clearTimeout(this.time)
            this.time = setTimeout(() => this.show = false, 3000)
        },
        touchendHandle(e) {
            this.info.device_url = this.info.url.substring(0, this.info.url.indexOf('?')) + `?starttime=${this.convertToCustomFormat(e.current)}&endtime=${this.convertToCustomFormat(e.endTime)}`
        },
        convertToCustomFormat(dateString) {
            // 创建日期对象
            const date = new Date(+dateString);

            // 获取年、月、日、小时、分钟和秒
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            // 格式化为目标字符串格式
            const formattedDate = `${year}${month}${day}T${hours}${minutes}${seconds}z`;
            return formattedDate;
        }
    },
    watch: {
        'info.device_url': {
            handler(newval, _) {
                try {
                    this.flvPlayer.pause && this.flvPlayer.pause()
                    this.flvPlayer.destroy && this.flvPlayer.destroy()
                    this.flvPlayer = null
                    this.showVideo = false
                    this.showVideo = true
                    this.info.uuid = this.info.uuid + Math.floor(Math.random() * 100)
                    setTimeout(() => this.play(), 3000)
                } catch (error) {
                    console.log(error)
                }
            }
        },
        show(newval, _) {
            if (newval) {
                if (this.time) clearTimeout(this.time)
                this.time = setTimeout(() => this.show = false, 3000)
            } else {
                clearTimeout(this.time)
            }
        }
    },
    beforeDestroy() {
        clearTimeout(this.time)
        clearTimeout(this.playTime)
    },
    destroyed() {
        this.flvPlayer.destroy && this.flvPlayer.destroy()
    }
}
</script>

<style lang="scss" scoped>
.mask,
.mask2 {
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mask2 {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
}

@keyframes spinner-border-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spinner-border-spin 1.2s linear infinite;
}

.supervisory-container {
    position: relative;
}

.controls {
    position: absolute;
    bottom: 0;
    left: 0;
}
</style>