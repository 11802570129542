import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/index.vue'
import detail from '@/views/detail.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    name: 'index',
    component: HomeView
  },
  {
    path: '/detail',
    name: 'detail',
    component: detail
  }
]

const router = new VueRouter({
  routes
})

export default router
