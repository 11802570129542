<template>
    <div class="detail-container">
        <MyVideo :info="info" :controls="true" :timer="time" :callback.sync="cb" :error.sync="info.error"
            ref="videoRef" />
        <div class=" control">
            <div class="left">
                <div @click="nonsupport"><img src="@/assets/arrow.png"></div>
                <div @click="nonsupport"><img src="@/assets/arrow.png"></div>
                <div @click="nonsupport"><img src="@/assets/arrow.png"></div>
                <div @click="nonsupport"><img src="@/assets/arrow.png"></div>
                <div @click="nonsupport"><img src="@/assets/square.png"></div>
                <div @click="nonsupport"><img src="@/assets/arrow.png"></div>
                <div @click="nonsupport"><img src="@/assets/arrow.png"></div>
                <div @click="nonsupport"><img src="@/assets/arrow.png"></div>
                <div @click="nonsupport"><img src="@/assets/arrow.png"></div>
            </div>
            <div class="right">
                <div @click="nonsupport"><img src="@/assets/add.png" alt=""><span>变倍</span><img src="@/assets/sup.png"
                        alt=""></div>
                <div @click="nonsupport"><img src="@/assets/focus.png" alt=""><span>聚焦</span><img
                        src="@/assets/out-focus.png" alt=""></div>
                <div @click="nonsupport"><img src="@/assets/aperture.png" alt=""><span>光圈</span><img
                        src="@/assets/aperture1.png" alt="">
                </div>
            </div>
        </div>
        <div class="playback">
            <Demo @touchEnd="touchEndHandle" v-if="history" />
            <div>
                <span>播放速度:</span>
                <el-select v-model="info.speed">
                    <el-option v-for=" item  in  [1, 5, 10] " :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
            </div>
            <div>
                <span>起始时间:</span>
                <el-date-picker v-model="time.startTime" type="date" placeholder="选择起始日期时间"
                    value-format="timestamp"></el-date-picker>
            </div>
            <div style="display: flex;justify-content: flex-end;">
                <el-button @click="clickHandle">确认</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import MyVideo from '@/components/supervisory.vue'
import Demo from '@/components/demo.vue'
import dayjs from 'dayjs'
export default {
    components: { MyVideo, Demo },
    data() {
        return {
            baseUrl: '',
            info: {
                speed: 1,
            },
            time: {
                startTime: '',
                endTime: '',
            },
            history: false,
            cb: null
        }
    },
    methods: {
        clickHandle() {
            this.history = true
            this.info.name = this.info.name + Math.floor(Math.random() * 100)
            this.info.device_url = `${this.baseUrl.replace('Channels', 'tracks').replace(/(tracks\/\d+?)(\d)(\?|$)/, (match, p1, p2, p3) => {
                return p1 + '1' + p3;
            })}?starttime=${this.convertToCustomFormat(this.time.startTime)}`
        },
        convertToCustomFormat(dateString) {
            // 创建日期对象
            const date = new Date(dateString);
            console.log(dateString)
            // 获取年、月、日、小时、分钟和秒
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            // 格式化为目标字符串格式
            const formattedDate = `${year}${month}${day}T${hours}${minutes}${seconds}z`;
            return formattedDate;
        },
        nonsupport() {
            this.$message.warning('当前设备无法支持此功能！')
        },
        touchEndHandle(e) {
            this.info.name = this.info.name + Math.floor(Math.random() * 100)
            this.info.device_url = `${this.baseUrl.replace('Channels', 'tracks').replace(/(tracks\/\d+?)(\d)(\?|$)/, (match, p1, p2, p3) => {
                return p1 + '1' + p3;
            })}?starttime=${this.convertToCustomFormat(`${dayjs(this.time.startTime).format('YYYY-MM-DD')}  ${e}`)}`
        }
    },
    created() {
        this.info = JSON.parse(this.$route.query.info)
        this.baseUrl = this.info.device_url
    },
    mounted() {
        this.cb && this.cb()
    },
    beforeDestroy() {
        console.log('开始销毁组件')
        this.$refs.videoRef.destroyHnalde()
    }
}
</script>

<style lang="scss" scoped>
.detail-container {
    background-color: #eff1f4;
    height: 100vh;

    .control {
        padding: 20px;
        display: flex;
        background-color: #fff;

        .left {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                background-color: #45627d;
                border-radius: 2px;
                margin-right: 2px;
                margin-bottom: 1px;

                &:nth-child(2) img {
                    transform: rotate(45deg);
                }

                &:nth-child(3) img {
                    transform: rotate(90deg);
                }

                &:nth-child(4) img {
                    transform: rotate(315deg);
                }

                &:nth-child(6) img {
                    transform: rotate(135deg);
                }

                &:nth-child(7) img {
                    transform: rotate(265deg);
                }

                &:nth-child(8) img {
                    transform: rotate(225deg);
                }

                &:nth-child(9) img {
                    transform: rotate(180deg);
                }

                img {
                    width: 50%;
                    height: 50%;
                }
            }
        }

        .right {
            flex: 1.5;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            div {
                display: flex;
                align-items: center;

                img {
                    width: 30px;
                    height: 30px;
                }

                span {
                    margin: 0 20px;
                }
            }
        }
    }

    .playback {
        padding: 20px;
        background-color: #fff;
        margin-top: 10px;

        div {
            margin-bottom: 10px;

            span {
                margin-right: 20px;
            }
        }
    }
}
</style>