<template>
    <div class="container">
        <canvas class="timeline-canvas" id="mymyCanvas" :width="width" :height="height" ref="canvasRef"></canvas>
    </div>
</template>

<script>
export default {
    data() {
        return {
            myCanvas: null,
            ctx: null,
            width: (window.innerWidth), // Use window size for responsiveness
            height: 50,
            curOffset: {
                x: window.innerWidth / 2,
            },
            offset: {
                x: window.innerWidth / 2,
            },
            x: 0,
            timeNum: 144,
            timeRatio: 24,
            ymd: '2022-10-27',
            hms: null,
            currentTIme: '00:00:00'
        };
    },
    mounted() {
        this.canvasInit();
        this.updateCanvasSize();
        this.drawTimeline();
        window.addEventListener('resize', this.updateCanvasSize); // Adjust on resize
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateCanvasSize);
    },
    methods: {
        canvasInit() {
            this.myCanvas = this.$refs.canvasRef;
            this.ctx = this.myCanvas.getContext('2d');

            if (this.myCanvas) {
                this.myCanvas.addEventListener('touchstart', this.onTouchStart);
                this.myCanvas.addEventListener('touchmove', this.onTouchMove);
                this.myCanvas.addEventListener('touchend', this.onTouchEnd);
                this.myCanvas.addEventListener('mousedown', this.onMouseDown);
            }
        },
        updateCanvasSize() {
            this.width = window.innerWidth;
            this.myCanvas.width = this.width;
            this.ctx.translate(this.offset.x, 0);
            this.drawTimeline();
        },
        drawTimeline() {
            this.ctx.clearRect(0, 0, this.width, this.height);
            this.baselineFun(this.offset.x);
            this.horizontalLine();
            this.scaleLine();
        },
        onTouchStart(e) {
            this.x = e.touches[0].clientX;
            window.addEventListener('touchmove', this.onTouchMove);
            window.addEventListener('touchend', this.onTouchEnd);
        },
        onTouchMove(e) {
            this.vueMoveFun(e.touches[0].clientX);
        },
        onTouchEnd(e) {
            this.curOffset.x = this.offset.x;
            window.removeEventListener('touchmove', this.onTouchMove);
            window.removeEventListener('touchend', this.onTouchEnd);
            this.$emit('touchEnd', this.currentTIme)
        },
        vueMoveFun(x) {
            this.updateCanvasSize();
            // 计算新的 offset.x
            this.offset.x = this.curOffset.x + x - this.x;
            // 计算最大和最小的 offset.x 边界
            const maxOffsetX = window.innerWidth / 2; // 右边界，可以调整为你需要的值
            const minOffsetX = -(this.timeNum * 30 - this.width / 2); // 左边界
            console.log(`maxOffsetX：${maxOffsetX}`, `minOffsetX：${minOffsetX}`, `X：${x}`)
            // 调整 offset.x 以保证在有效范围内
            if (this.offset.x > maxOffsetX) {
                this.offset.x = maxOffsetX;
            } else if (this.offset.x < minOffsetX) {
                this.offset.x = minOffsetX;
            }

            this.drawTimeline();
        },
        // 中心基准线
        baselineFun(x) {
            this.ctx.beginPath();
            this.ctx.strokeStyle = "red";
            this.ctx.lineWidth = 1;
            this.ctx.moveTo(window.innerWidth / 2 - x, 0);
            this.ctx.lineTo(window.innerWidth / 2 - x, this.height);
            this.ctx.font = "14px SimSun, Songti SC";
            this.ctx.fillStyle = "#000";
            this.ctx.fillText(this.fixedTime(this.offset.x), window.innerWidth / 2 - x - 73, this.height / 2 - 10);
            this.ctx.stroke();
        },

        // 水平线
        horizontalLine() {
            this.ctx.beginPath();
            this.ctx.strokeStyle = "#000";
            this.ctx.lineWidth = 1;
            this.ctx.lineCap = "round";
            this.ctx.moveTo(0, this.height);
            this.ctx.lineTo(this.timeNum * 30, this.height);
            this.ctx.stroke();
        },

        // 刻度线
        scaleLine() {
            this.ctx.font = "12px SimSun, Songti SC";
            for (let i = 0; i <= this.timeNum; ++i) {
                this.ctx.beginPath();
                this.ctx.lineWidth = 1;
                this.ctx.moveTo(i * 30, this.height);
                i % 5 === 0 ? this.ctx.lineTo(i * 30, this.height - 15) : this.ctx.lineTo(i * 30, this.height - 10)
                this.ctx.stroke();

                if (i % 6 === 0) {
                    const timeLabel = this.computationTime(i);
                    this.ctx.fillText(timeLabel, i * 30 - (i / 6 * 30) - 28, this.height - 18);
                }
            }
        },

        // 时间计算
        computationTime(num) {
            const timeInSeconds = num * this.getTimeIntervalInSeconds();
            const hours = Math.floor(timeInSeconds / 3600);
            const minutes = Math.floor((timeInSeconds % 3600) / 60);
            const seconds = Math.floor(timeInSeconds % 60);

            // 确保时间在 00:00:00 到 23:59:59 范围内
            const displayHours = (hours % 24) < 10 ? "0" + (hours % 24) : (hours % 24);
            const displayMinutes = minutes < 10 ? "0" + minutes : minutes;
            const displaySeconds = seconds < 10 ? "0" + seconds : seconds;
            return hours === 24 ? `23:59:59` : `${displayHours}:${displayMinutes}:${displaySeconds}`
        },

        // 获取时间间隔秒数
        getTimeIntervalInSeconds() {
            switch (this.timeNum) {
                case 12:
                    return 2 * 3600; // 2 hours in seconds
                case 24:
                    return 3600; // 1 hour in seconds
                case 48:
                    return 30 * 60; // 30 minutes in seconds
                case 144:
                    return 10 * 60; // 10 minutes in seconds
                case 288:
                    return 5 * 60; // 5 minutes in seconds
                default:
                    return 0;
            }
        },

        // 转换分钟到时分
        toHourMinute(minutes) {
            const h = Math.floor(minutes / 60);
            const m = minutes % 60;
            return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`;
        },

        // 固定时间
        fixedTime(x) {
            const origin = x - window.innerWidth / 2;
            let timeInSeconds = Math.abs(origin) * this.timeRatio;

            // 限制时间不超过最大值 23:59:59
            const maxTimeInSeconds = 23 * 3600 + 59 * 60 + 59;
            if (timeInSeconds > maxTimeInSeconds) {
                timeInSeconds = maxTimeInSeconds;
            }

            const h = Math.floor(timeInSeconds / 3600);
            const m = Math.floor((timeInSeconds % 3600) / 60);
            const s = Math.floor(timeInSeconds % 60);
            this.currentTIme = `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`
            return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    position: relative;
}

.timeline-canvas {
    background: rebeccapurple;
    width: 100%;
    /* Full width for responsiveness */
    height: 50px;
}
</style>