<template>
    <div class="list-container" ref="container">
        <p v-if="loading" class="loading-text">加载中...</p>
        <p v-else-if="allLoaded" class="end-text">没有更多数据了</p>
    </div>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        allLoaded: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {}
    },
    methods: {
        initObserver() {
            this.observer = new IntersectionObserver((entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    this.$emit('loadMore')
                }
            }, { threshold: 0.1 });
            this.observer.observe(this.$refs.container);
        }
    },
    mounted() {
        this.initObserver()
    }
}
</script>

<style lang="scss" scoped>
.loading-text,
.end-text {
    text-align: center;
    color: #888;
    margin: 20px 0;
}
</style>