<template>
    <div class="slider-container">
        <div class="controls">
            <div id="sliderValue">{{ formatTime(value) }}</div>
        </div>
        <input type="range" v-model="value" :min="progress.startTime" :max="progress.endTime" @input="updateFill"
            ref="slider" style="width: 100vw;height: 6px;" @touchend="touchendHandle" />
    </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
    props: {
        progress: {
            type: Object,
            default: () => ({
                endTime: 0,
                startTime: Date.now()
            })
        }
    },
    data() {
        return {
            value: 0,
            time: null
        };
    },
    mounted() { },
    methods: {
        updateFill() {
            if (!this.progress.startTime) return
            const slider = this.$refs.slider;
            const percentage = ((this.value - this.progress.startTime) / (this.progress.endTime - this.progress.startTime)).toFixed(2) * 100;
            slider.style.setProperty('--slider-value', `${Math.max(0, Math.min(100, percentage))}%`);
        },
        formatTime(date) {
            return dayjs(+date).format('YYYY-MM-DD HH:mm:ss')
        },
        play() {
            if (!this.progress?.startTime) return
            if (!this.value) this.value = this.progress.startTime
            this.time = setInterval(() => {
                this.value = +this.value + 1000
                if (this.value >= this.progress?.endTime) clearInterval(this.time)
            }, 1000);
        },
        pausePlay() {
            clearInterval(this.time)
        },
        touchendHandle() {
            this.$emit('touchendHandle', { current: this.value, startTime: this.progress.startTime, endTime: this.progress.endTime })
        }
    },
    watch: {
        value(newval) {
            this.$emit('changeHandle', newval)
            this.updateFill();
        }
    },
    beforeDestroy() {
        clearInterval(this.time)
    }
};
</script>

<style scoped>
.slider-container {
    width: 300px;
    text-align: center;
    position: relative;
}

.controls input[type="range"] {
    -webkit-appearance: none;
    /* Remove default styling */
    width: 100%;
    height: 8px;
    background: linear-gradient(to right, #4caf50 var(--slider-value), #ddd var(--slider-value));
    border-radius: 5px;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    cursor: pointer;
    position: relative;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: transparent;
    /* Transparent to show the gradient */
    border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Remove default styling */
    appearance: none;
    width: 12px;
    height: 12px;
    background: #4caf50;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    top: -2px;
    /* Adjust this value to vertically center the thumb */
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    background: transparent;
    /* Transparent to show the gradient */
    border-radius: 5px;
}

input[type="range"]::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4caf50;
    cursor: pointer;
    border-radius: 50%;
}

input[type="range"]::-ms-track {
    width: 100%;
    height: 8px;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-ms-fill-lower {
    background: #4caf50;
    border-radius: 5px;
}

input[type="range"]::-ms-fill-upper {
    background: #ddd;
    border-radius: 5px;
}

input[type="range"]::-ms-thumb {
    width: 25px;
    height: 25px;
    background: #4caf50;
    cursor: pointer;
    border-radius: 50%;
}

#sliderValue {
    padding-left: 10px;
    display: flex;
    color: #fff;
    font-size: 12px;
}
</style>