<template>
    <div class="index-container">
        <div v-if="supervisoryList.length" style="padding-top: 42px;">
            <div
                style="background-color: #fff;width: 100vw;height: 42px;position: fixed;top: 0;left: 0; z-index: 999999;display: flex;justify-content: space-between;align-items: center;">
                <el-select v-model="value" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div style="margin-left: 20px;">versions：5.0.1</div>
            </div>
            <MyVideo v-for="(item, i) in pageData" :key="`${item.category_name}${item.name}${i}`" :info="item"
                @clickHandle="clickHandle" style="margin-bottom: 5px;" :callback.sync="item.cb"
                :status.sync="item.status" :error.sync="item.error" :ref="item.name" />
            <Loading @loadMore="loadMore" :loading="loading" :allLoaded="allLoaded" />
        </div>
        <div v-else>
            <el-empty description="暂无数据"></el-empty>
        </div>
    </div>
</template>

<script>
import MyVideo from '@/components/supervisory.vue'
import Loading from '@/components/loading.vue'
import axios from 'axios'

export default {
    components: { MyVideo, Loading },
    data() {
        return {
            options: [],
            supervisoryList: [],
            value: '',
            observer: null,
            time: null,
            list: [],
            page: null,
            pageData: [],
            loading: false,
            allLoaded: false,
            pageNumber: 5
        }
    },
    methods: {
        clickHandle(info) {
            info.error = false
            this.$router.push(`/detail?info=${JSON.stringify(info)}`)
        },
        loadMore() {
            if (this.pageData.length === this.list.length) return this.allLoaded = true
            this.loading = true
            this.page += 1
        },
        async getCoverPlan(data) {
            await axios({
                url: `${data[0].custom_url}/websocket/getCoverPlan`,
                method: 'post',
                data: {
                    list: JSON.stringify(data)
                }
            })
        },
        queue() {
            const inexEcutionNumber = () => this.supervisoryList.filter(item => item.status == 1).length
            for (let i = 0; i < this.supervisoryList.length; i++) {
                if (inexEcutionNumber() >= 3) break
                if (this.supervisoryList[i].status == 0) {
                    this.supervisoryList[i].status = 1
                    this.supervisoryList[i].cb && this.supervisoryList[i].cb()
                }
            }
            if (this.time) clearTimeout(this.time)
            this.time = setTimeout(() => {
                console.log(this.supervisoryList)
                if (inexEcutionNumber() < 3) this.queue()
            }, 15000)
        }
    },
    created() {
        if (!this.$route.query.data) this.$message.error('获取列表失败，请联系管理人员！')
        if (this.$route.query.data) {
            const data = JSON.parse(this.$route.query.data || '{}')
            if (Array.isArray(data) && data.length) {
                localStorage.setItem('data', this.$route.query.data)
                this.options = data.map(item => {
                    return {
                        label: item.category_name,
                        value: item.category_name
                    }
                })
                const promise = data.map(item => {
                    item.list.forEach((sub_item, i) => {
                        sub_item.uuid = `test${i}${Math.floor(Math.random() * 100)}`
                        sub_item.status = 0
                        sub_item.cb = null
                        sub_item.error = false
                    })
                    this.supervisoryList.push(...item.list)
                    // return this.getCoverPlan(item.list)
                })
                this.value = data[0].category_name
                // Promise.all(promise) 
            }
        }
    },
    watch: {
        value(newVal, _) {
            this.list = this.supervisoryList.filter(item => item.category_name === this.value)
            this.pageData = []
            this.page = 1
            this.allLoaded = false
        },
        page: {
            handler(newVal, _) {
                const start = (this.page - 1) * this.pageNumber;
                const end = start + this.pageNumber;
                this.pageData = [...this.pageData, ...this.list.slice(start, end)]
                this.loading = false
                console.log(this.pageData)
            }
        }
    },
    mounted() {
        /* this.queue() */
    },
    beforeDestroy() {
        clearTimeout(this.time)
    }
}
</script>

<style lang="scss" scoped></style>